import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Alert,
    CircularProgress,
    Autocomplete,
    Typography
} from '@mui/material';
import api from '../../services/api';

const ConsignmentForm = ({ open, onClose, onSuccess }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        type: 'SUPPLIER',
        reference: '',
        userName: '',
        consignment_date: new Date().toLocaleDateString('en-CA', { 
            timeZone: 'America/New_York' 
        }),
        supplier_id: null
    });
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSuppliers, setLoadingSuppliers] = useState(false);
    const [error, setError] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [editingType, setEditingType] = useState(false);
    const [editingSupplier, setEditingSupplier] = useState(false);

    // Add state for saved usernames
    const [savedUserNames, setSavedUserNames] = useState(() => {
        const saved = localStorage.getItem('savedUserNames');
        return saved ? JSON.parse(saved) : [];
    });

    // Fetch suppliers when form opens
    useEffect(() => {
        if (open) {
            loadSuppliers();
        }
    }, [open]);

    const loadSuppliers = async () => {
        setLoadingSuppliers(true);
        try {
            const response = await api.listSuppliers();
            setSuppliers(response.data || []);
            setError(null);
        } catch (err) {
            console.error('Error loading suppliers:', err);
            setError('Failed to load suppliers - you can continue without selecting a supplier');
        } finally {
            setLoadingSuppliers(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSupplierChange = (event, newValue) => {
        setSelectedSupplier(newValue);
        setFormData(prev => ({
            ...prev,
            supplier_id: newValue?.id || null
        }));
    };

    const getFullReference = () => {
        if (!formData.reference) return '';
        return `${formData.reference}-${formData.userName}-${getDateSuffix()}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        
        const consignmentData = {
            name: getFullReference(),
            type: formData.type,
            status: "OPEN",
            outlet_id: "06f2e29c-25cb-11ee-ea12-b003e5dfeaf0",
            supplier_id: formData.supplier_id,
            consignment_date: formData.consignment_date
        };
        
        try {
            const response = await api.createConsignment(consignmentData);
            console.log('Form Submit Response:', response);

            // Check if the ID is present in the correct location
            const consignmentId = response?.data?.data?.id;
            if (!consignmentId) {
                throw new Error('Consignment ID not found in response');
            }

            onSuccess(response);
            onClose();
            // Navigate to the new consignment
            navigate(`/consignments/${consignmentId}`);
        } catch (error) {
            console.error('Form Submit Error:', error);
            setError(error.response?.data?.message || error.message || 'Failed to create consignment');
        } finally {
            setLoading(false);
        }
    };

    // Update getDateSuffix to handle timezone correctly
    const getDateSuffix = () => {
        // Split the date string and create a new Date object in EST
        const [year, month, day] = formData.consignment_date.split('-');
        const date = new Date(`${year}-${month}-${day}T12:00:00-05:00`); // Use noon EST to avoid any date rollover issues
        
        return date.toLocaleDateString('en-US', {
            timeZone: 'America/New_York',
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        }).replace(/\//g, '-');
    };

    const handleQuickFill = (prefix) => {
        setFormData(prev => ({
            ...prev,
            reference: prefix
        }));
    };

    // Handle username selection/input
    const handleUserNameChange = (event, newValue) => {
        // If newValue is null (clear) or a string (free input), use it directly
        const userName = typeof newValue === 'string' ? newValue : newValue?.name || '';
        setFormData(prev => ({
            ...prev,
            userName
        }));
    };

    // Handle saving new username
    const handleSaveUserName = () => {
        if (formData.userName && !savedUserNames.some(u => u.name === formData.userName)) {
            const newUserNames = [...savedUserNames, { name: formData.userName }];
            setSavedUserNames(newUserNames);
            localStorage.setItem('savedUserNames', JSON.stringify(newUserNames));
        }
    };

    // Handle removing username from saved list
    const handleRemoveUserName = (nameToRemove) => {
        const newUserNames = savedUserNames.filter(u => u.name !== nameToRemove);
        setSavedUserNames(newUserNames);
        localStorage.setItem('savedUserNames', JSON.stringify(newUserNames));
    };

    // Helper to get type display text
    const getTypeDisplay = () => {
        switch(formData.type) {
            case 'SUPPLIER': return 'Supplier';
            case 'STOCKTAKE': return 'Stocktake';
            case 'RETURN': return 'Return';
            default: return '';
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="sm" 
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: { xs: 2, sm: 4 },
                    width: { xs: 'calc(100% - 32px)', sm: '600px' }
                }
            }}
        >
            <DialogTitle>New Consignment</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {typeof error === 'string' ? error : 'An error occurred'}
                        </Alert>
                    )}
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: { xs: 1.5, sm: 2 }
                    }}>
                        {/* Type Field */}
                        {editingType ? (
                            <FormControl fullWidth size="small">
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name="type"
                                    value={formData.type}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setEditingType(false);
                                    }}
                                    label="Type"
                                    required
                                    size="small"
                                    onBlur={() => setEditingType(false)}
                                    autoFocus
                                >
                                    <MenuItem value="SUPPLIER">Supplier</MenuItem>
                                    <MenuItem value="STOCKTAKE">Stocktake</MenuItem>
                                    <MenuItem value="RETURN">Return</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <Box 
                                onClick={() => setEditingType(true)}
                                sx={{
                                    p: 1,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'action.hover',
                                        borderRadius: 1
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1
                                }}
                            >
                                <Typography variant="body2" color="text.secondary">
                                    Type:
                                </Typography>
                                <Typography>
                                    {getTypeDisplay()}
                                </Typography>
                            </Box>
                        )}

                        {/* Supplier Field */}
                        {formData.type === 'SUPPLIER' && (
                            editingSupplier ? (
                                <Autocomplete
                                    size="small"
                                    options={suppliers}
                                    getOptionLabel={(option) => option.name || ''}
                                    loading={loadingSuppliers}
                                    value={selectedSupplier}
                                    onChange={(e, newValue) => {
                                        handleSupplierChange(e, newValue);
                                        setEditingSupplier(false);
                                    }}
                                    onBlur={() => setEditingSupplier(false)}
                                    autoFocus
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            label="Supplier (Optional)"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loadingSuppliers ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            ) : (
                                <Box 
                                    onClick={() => setEditingSupplier(true)}
                                    sx={{
                                        p: 1,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bgcolor: 'action.hover',
                                            borderRadius: 1
                                        },
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}
                                >
                                    <Typography variant="body2" color="text.secondary">
                                        Supplier:
                                    </Typography>
                                    <Typography>
                                        {selectedSupplier?.name || 'None selected'}
                                    </Typography>
                                </Box>
                            )
                        )}

                        <Box>
                            <TextField
                                name="reference"
                                label="PO Number / Reference"
                                value={formData.reference}
                                onChange={handleChange}
                                fullWidth
                                required
                                InputProps={{
                                    endAdornment: (
                                        <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                                            {formData.userName && `-${formData.userName}`}-{getDateSuffix()}
                                        </span>
                                    ),
                                }}
                            />
                            <Box sx={{ 
                                mt: 1, 
                                display: 'flex', 
                                gap: 1,
                                flexWrap: 'wrap'
                            }}>
                                <Button 
                                    size="small" 
                                    variant="outlined" 
                                    onClick={() => handleQuickFill('Fill-In')}
                                >
                                    Fill-In
                                </Button>
                                <Button 
                                    size="small" 
                                    variant="outlined" 
                                    onClick={() => handleQuickFill('Cx Order')}
                                >
                                    Cx Order
                                </Button>
                                <Button 
                                    size="small" 
                                    variant="outlined" 
                                    onClick={() => handleQuickFill('New Receiving')}
                                >
                                    New Receiving
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ 
                            display: 'flex', 
                            gap: 1, 
                            alignItems: 'flex-start',
                            '& .MuiAutocomplete-root': {
                                flexGrow: 1,
                                minWidth: '250px'  // Ensure minimum width
                            }
                        }}>
                            <Autocomplete
                                freeSolo
                                size="small"
                                options={savedUserNames}
                                getOptionLabel={(option) => {
                                    // Handle both string and object inputs
                                    return typeof option === 'string' ? option : option.name;
                                }}
                                value={formData.userName}
                                onChange={handleUserNameChange}
                                onInputChange={(event, newValue) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        userName: newValue
                                    }));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Your Name"
                                        required
                                        fullWidth
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <Box component="li" {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{option.name}</span>
                                        <Button
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveUserName(option.name);
                                            }}
                                            sx={{ minWidth: 'auto', p: 0.5 }}
                                        >
                                            ×
                                        </Button>
                                    </Box>
                                )}
                            />
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleSaveUserName}
                                disabled={!formData.userName || savedUserNames.some(u => u.name === formData.userName)}
                            >
                                Save
                            </Button>
                        </Box>

                        <TextField
                            name="consignment_date"
                            label="Consignment Date"
                            type="date"
                            value={formData.consignment_date}
                            onChange={handleChange}
                            fullWidth
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ 
                    p: { xs: 2, sm: 3 },
                    flexDirection: { xs: 'column', sm: 'row' },
                    '& > button': {
                        width: { xs: '100%', sm: 'auto' }
                    }
                }}>
                    <Button 
                        onClick={onClose}
                        sx={{ mb: { xs: 1, sm: 0 } }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Consignment'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ConsignmentForm;
